@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

.oswald-font {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}/* Root variables for light theme */
:root {
  --background-color: #F4ECE4; /* Light warm beige */
  --text-color: #333333;
  --header-background: #5A2B21; /* Darker Burnt Umber for headers */
  --section-background: #FFF8F2; /* Soft off-white with warm undertones */
  --accent-color: #8A3324; /* Burnt Umber */
  --accent-color2: #B2543B; /* Lighter Burnt Umber for hover or secondary accents */
  --sidebar-color: #EAE3DA; /* Soft light tan */
  --separator-color: rgba(0, 0, 0, 0.1);
  --shadow-color: rgba(0, 0, 0, 0.2);
  --sidebar-width: 250px;
  --sidebar-closed-width: 60px;
}

/* Root variables for dark theme */
[data-theme='dark'] {
  --background-color: #2B2B2B; /* Dark charcoal with a warm hint */
  --text-color: #EAEAEA; /* Softer white for readability */
  --header-background: #4A2320; /* Deep, warm Burnt Umber tone */
  --section-background: #33302E; /* Dark, warm gray for sections */
  --accent-color: #A3472B; /* Burnt Umber */
  --accent-color2: #BF6650; /* Warmer Burnt Umber for hover or secondary accents */
  --sidebar-color: #2E2B28; /* Darker warm gray for sidebar */
  --separator-color: rgba(255, 255, 255, 0.15);
  --shadow-color: rgba(0, 0, 0, 0.6);
  --sidebar-width: 250px;
  --sidebar-closed-width: 60px;
}




/* Global reset and typography */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
html {
  font-family: 'Oswald', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--background-color) !important; /* Override Bootstrap background */
  color: var(--text-color);
  min-height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
a,
input[type="submit"] {
  transition: background-color 0.3s ease;
}


/* Main app container */
.app-container {
  max-width: 100%;
  padding: 20px;
  transition: margin-left 0.3s ease;
  min-height: 100vh;
}

.sidebar.open ~ .page-content {
  margin-left: var(--sidebar-width);
}

.sidebar:not(.open) ~ .page-content {
  margin-left: var(--sidebar-closed-width);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .sidebar.open ~ .page-content,
  .sidebar:not(.open) ~ .page-content {
    margin-left: 0;
  }
  .app-container {
    padding: 10px;
  }
}
