/* Footer Container */
.footer {
    background-color: var(--section-background);
    color: var(--text-color);
    padding: 40px 20px;
    text-align: center;
    position: relative;
    width: calc(100% - var(--sidebar-closed-width));
    margin-left: var(--sidebar-closed-width);
    box-sizing: border-box;
    transition: margin-left 0.3s ease, width 0.3s ease;
    border-radius: 8px;
}

/* Footer Header */
.footer-header h2 {
    font-size: 1.8rem;
    color: var(--accent-color);
    margin-bottom: 20px;
}

/* Social Icons as a Column */
.footer-socials {
    display: flex;
    flex-direction: column; /* Stack icons vertically */
    align-items: center;
    gap: 20px;
    font-size: 2rem;
    margin-bottom: 30px;
}

.footer-socials a {
    color: var(--text-color);
    font-size: 2rem;
    transition: color 0.3s ease;
}

.footer-socials a:hover {
    color: var(--accent-color);
}

/* Override SVG size */
.footer-socials a svg {
    width: 1.5em;
    height: 1.5em;
}

/* Footer Columns */
.footer-columns {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 30px;
    text-align: center;
}

/* Individual Footer Column */
.footer-column {
    flex: 1;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Centered Column Headings */
.footer-column h3 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: var(--accent-color);
    text-align: center;
}

/* Column Text and Links */
.footer-column p,
.footer-column a {
    font-size: 1rem;
    color: var(--text-color);
    margin: 5px 0;
    text-decoration: none;
    text-align: center;
}

.footer-column a:hover {
    color: var(--accent-color);
}

/* Footer Bottom */
.footer-bottom {
    font-size: 0.9rem;
    color: var(--text-color);
    text-align: center;
}

.footer-bottom p {
    margin: 5px 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .footer {
        width: 100%;
        margin-left: 0;
    }

    .footer-columns {
        flex-direction: column;
        align-items: center;
    }

    .footer-column {
        text-align: center;
        margin-bottom: 20px;
    }

    .footer-socials {
        margin-bottom: 20px;
        font-size: 2rem;
    }
}
