.rates-and-policies {
    color: var(--text-color);
    padding: 40px 20px;
    margin: 20px auto;
    max-width: 80vw;
    text-align: center;
}

/* Scroll container for sliding effect on mobile */
.scroll-container {
    overflow-x: auto;
    display: flex;
    width: 100%;
    padding: 10px 0;
    scroll-snap-type: x mandatory;
}

/* Card container for desktop layout */
.card-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 85vw;
}

/* Individual Cards */
.info-card {
    background-color: var(--section-background);
    border-radius: 8px;
    box-shadow: 0px 4px 8px var(--shadow-color);
    padding: 20px;
    min-width: 250px;
    max-width: 300px;
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    scroll-snap-align: center;
}

.info-card h3 {
    font-size: 1.5rem;
    color: var(--accent-color);
    margin-bottom: 15px;
}

.info-card p {
    font-size: 1rem;
    color: var(--text-color);
    line-height: 1.6;
    margin: 10px 0;
}

.cta-button {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: var(--accent-color2);
    color: white !important;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: var(--accent-color);
}

/* Responsive Design */
@media (max-width: 768px) {
    .card-container {
        display: flex;
        gap: 20px;
        flex-wrap: nowrap; /* Prevent wrapping for horizontal scroll */
    }
}
