.contact {
    margin: 0 auto;
    padding: 20px;
    background-color: var(--section-background);
    color: var(--text-color);
    border-radius: 10px;
    box-shadow: 0px 4px 8px var(--shadow-color);
    text-align: left;
}

.contact-title {
    font-size: 2rem;
    margin-bottom: 10px;
    color: var(--accent-color);
    text-align: center;
}

.contact-description {
    font-size: 1.1rem;
    color: var(--text-color);
    margin-bottom: 20px;
    text-align: center;
}

.contact-form .form-group {
    margin-bottom: 20px;
}

.contact-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: var(--text-color);
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--separator-color);
    border-radius: 4px;
    background-color: var(--background-color);
    color: var(--text-color);
    box-sizing: border-box;
}

/* Inline group styling for email and phone fields */
.inline-group {
    display: flex;
    gap: 20px;
}

.inline-group .form-group {
    flex: 1; /* Allow each field to take up equal space */
}

.contact-form input[type="file"] {
    padding: 0;
    color: var(--text-color);
    font-size: 0.9rem;
}

.submit-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: var(--accent-color2);
    color: white;
    border-radius: 5px;
    text-decoration: none;
    border: none;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.submit-button:hover {
    background-color: var(--accent-color);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .contact {
        padding: 15px;
    }

    .contact-title {
        font-size: 1.6rem;
    }

    .contact-description {
        font-size: 1rem;
    }

    .contact-form .form-group {
        margin-bottom: 15px;
    }

    /* Stack email and phone on mobile */
    .inline-group {
        flex-direction: column;
    }

    .submit-button {
        width: 100%;
        padding: 12px;
    }
}
