/* Sidebar General Layout */
.sidebar {
  width: var(--sidebar-closed-width);
  height: 100vh;
  background-color: var(--sidebar-color);
  color: var(--text-color);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  transition: width 0.3s ease, background-color 0.3s ease;
  z-index: 1000;
  box-shadow: 4px 0 8px var(--shadow-color);
}

.sidebar.hovered {
  width: var(--sidebar-width);
}/* Mobile-specific sidebar behavior */

@media (min-width: 768px) {
  .nav {
      display: flex;
      flex-direction: column;
      gap: 15px;
      flex-grow: 1; /* Allow the nav to take up available space */
      justify-content: center; /* Center links vertically */
  }
  
}

@media (max-width: 768px) {
  .sidebar {
      transform: translateX(-70vw); /* Initially hidden off-screen by 70% width */
      transition: transform 0.3s ease; /* Smooth slide transition */
      width: 70vw; /* Set to 70% width for mobile view */
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      z-index: 1050;
      background-color: var(--sidebar-color); /* Match color for consistency */
  }

  .sidebar.open {
      transform: translateX(0); /* Slide in */
  }

  .overlay.show {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1040; /* Overlay below sidebar */
  }

  .nav {
      margin-top: 50px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      flex-grow: 1; /* Allow the nav to take up available space */
  }
}


/* Sidebar Logo */
.logo {
  width: 100px;
  align-self: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sidebar.hovered .logo,
.sidebar.open .logo {
  background-color: none !important;
  opacity: 1;
}

/* Sidebar Links */
.nav {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Sidebar Links */
.nav a {
  color: var(--text-color);
  text-decoration: none;
  padding: 12px 0;
  font-size: 1.1rem;
  border-radius: 8px;
  transition: background 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Remove unnecessary z-index */
}

.sidebar.hovered .nav a,
.sidebar.open .nav a {
  justify-content: flex-start;
  padding-left: 20px;
}


.nav-icon {
  font-size: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  transition: margin 0.3s ease;
}

.sidebar.hovered .nav-icon,
.sidebar.open .nav-icon {
  margin-right: 10px;
  margin-left: 0;
}

.nav-text {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sidebar.hovered .nav-text,
.sidebar.open .nav-text {
  display: inline;
  opacity: 1;
}

/* Sidebar Footer */
.sidebar-footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid var(--separator-color);
  padding-bottom: 20px;
}
.sidebar.hovered .wallet-info span,
.sidebar.open .wallet-info span {
  opacity: 1;
  visibility: visible;
}

/* Logout Button */
.logout-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: var(--accent-color);
  color: var(--text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.sidebar.hovered .logout-button,
.sidebar.open .logout-button {
  opacity: 1;
  visibility: visible;
}

/* Theme Toggle Container */
.theme-toggle-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.sidebar.hovered .theme-toggle-container,
.sidebar.open .theme-toggle-container {
  opacity: 1;
  visibility: visible;
}

/* Switch - Slider Styling */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  z-index: 1010;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Theme label */
.theme-label {
  color: var(--text-color);
  font-size: 1rem;
  white-space: nowrap;
}

/* Mobile Hamburger Menu */
.hamburger {
  display: none;
  position: fixed;
  top: 15px;
  right: 15px; /* Align to the right */
  z-index: 1100;
  cursor: pointer;
}


.hamburger-logo {
  width: 60px; /* Adjust size as needed */
  height: 60px;
  object-fit: contain;
  font-size: 2rem; /* Size adjustment for "X" */
  line-height: 1;
  color: var(--text-color);
  background-color: var(--background-color);
  border-radius: 50%;
  padding: 2px;
}


@media (max-width: 768px) {
  .hamburger {
      display: block; /* Show hamburger icon on mobile */
  }
}


/* Overlay to close the sidebar when clicking outside */
.overlay.show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040; /* Ensure overlay is above content but below sidebar */
}


.sidebar.open {
  z-index: 1050; /* Sidebar should be above overlay */
}

.nav a {
  z-index: 1051; /* Ensure links stay above overlay */
}