/* Full-height and responsive width for carousel */
.hero-carousel {
    width: 80vw;
    max-width: 1200px; /* Optional max width to limit scaling on very large screens */
    margin: 0 auto; /* Center the carousel */
    box-shadow: 0px 4px 8px var(--shadow-color);
}

.hero-carousel .carousel-item {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--section-background);
    overflow: hidden;
}

/* Ensures images fully cover the container */
.hero-carousel .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

/* Caption Styling */
.carousel-caption h3 {
    font-size: 2rem;
    font-weight: bold;
    color: #EAEAEA;
    text-shadow: var(--shadow-color) 1px 1px 5px;
}

.carousel-caption p {
    font-size: 1.2rem;
    color: #fff;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .hero-carousel {
        width: 100%; /* Full width on smaller screens */
    }

    .carousel-caption h3 {
        font-size: 1.5rem;
    }

    .carousel-caption p {
        font-size: 1rem;
    }
}
